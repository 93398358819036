@keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}

.marquee {
    white-space: nowrap;
    overflow: hidden;
    position: relative; 
    width: 708px;
    height: 100%;
}

.marquee-content {
    animation: marquee 15s linear infinite;
}

.marquee-content span {
    padding-right: 10px; 
}

.bold {
    font-weight: bold;
}

.normal {
    font-weight: normal;
}

.low-opacity {
    opacity: 0.6;
}
