/* Custom radio button styles */
.custom-radio {
    appearance: none;
    width: 1.2rem;
    height: 1.2rem;
    background-color: #000;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
  }
  
  .custom-radio:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.8rem;
    height: 0.8rem;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="white"%3E%3Cpath d="M6.002 10.943l-3.53-3.53a.75.75 0 0 0-1.06 1.06l4 4a.75.75 0 0 0 1.06 0l8-8a.75.75 0 1 0-1.06-1.06l-7.47 7.47z"/%3E%3C/svg%3E');
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
  }
  