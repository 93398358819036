.header{
    min-height: 1315.5px;
    background-image: url('../assets/grid.png');
    max-height: 90vh;
}

.testimonials{
    background-image: url('../assets/grid.png');
}
.testimonials-card{
    background-image: url('../assets/testimonialsCard.png');
}