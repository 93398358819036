.theme-button-1 {
    color: #6b5770;
    background-image: linear-gradient(180deg, #8D75E6, rgb(213, 210, 216));
    display: inline-block;
    height: 35.75px;
    min-width: 111.56px;
    border: 2px solid;
    position: relative;
    z-index: 0;
    border-radius: 5px;
    color: #8D75E6;
    box-shadow: 0 4px 8px rgba(141, 117, 230, 0.5);
}

.theme-button-1::before {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(180deg, #8D75E6, rgb(213, 210, 216));
  z-index: -2;
  transition: .4s;
  border-radius: 5px;
}

.theme-button-1::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #fff, #fff);
  z-index: -1;
  transition: .4s;
  border-radius: 4px;
}
